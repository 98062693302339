import { useCookies } from "@vueuse/integrations/useCookies";

export function useMirai() {
  const cookies = useCookies(["Nami-Frontend"]);
  const isMiraiEnabled = ref(false);

  onMounted(() => {
    isMiraiEnabled.value = cookies.get("Nami-Frontend") === "mirai";

    watch(isMiraiEnabled, (v) => {
      if (v) {
        cookies.set("Nami-Frontend", "mirai", { path: "/" });
      } else {
        cookies.remove("Nami-Frontend", { path: "/" });
      }

      setTimeout(() => {
        window.location.reload();
      }, 150);
    });
  });

  return {
    isMiraiEnabled,
  };
}
